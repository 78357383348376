import React, { useState, useEffect } from "react";
import MainContent from "@widgets/content";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import ScaleImage from "@widgets/scaleImage";
import EndBlock from "@widgets/endBlock";
import PromoBlock from "@widgets/promoBlock";
import FAQItem from "@widgets/faq";

import LogoDash from '@assets/logo/dash.png';
import LogoID from '@assets/logo/api.png';
import Logo from '@assets/logo/leecyber.png';

import SEO from '@widgets/seo';
import DemoPopup from '@widgets/demoPopup';

export default function ID() {
  const [devId, setDevId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <MainContent>
      <DemoPopup showModal={showModal} setShowModal={setShowModal} />

      <SEO 
        title="ID"
        description="Единая система аутентификации и управления доступом для бизнеса."
        ogImage="https://cdn-a.leecyber.com/landing/og.png"
        ogUrl="https://leecyber.com/id"
        favicon={LogoID}
      />

      <div className={css.rbg} />
      <div className={css.hello}>
        <a className={css.serviceName}><img src={Logo} width={50} style={{ borderRadius: 10 }} /><img src={LogoDash} width={50} style={{ borderRadius: 10 }} /><img src={LogoID} width={50} style={{ borderRadius: 10 }} /></a>
        <h1>LeeCyber.ID</h1>
        <a>Единая система аутентификации и управления доступом для бизнеса</a>

        <div className={css.btns}>
           <Link className={css.connect} to={`https://docs.leecyber.com/id/`}>Попробовать сейчас</Link>
        </div>

        <ScaleImage img={'https://cdn-a.leecyber.com/landing/id.png'} />

        <div className={css.promoInfo}>
          <PromoBlock 
            icon={<i class="fi fi-rr-shield-check"></i>} 
            title="Безопасно" 
            desc="Храним все данные на территории Российской Федерации с учётом законодательных требований."
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-binary-lock"></i>} 
            title="Зашифровано" 
            desc="Все данные пользователей строго зашифрованы. Платформа обеспечивает первый уровень защищенности данных." 
          />
          <PromoBlock 
            icon={<i class="fi fi-rr-free"></i>} 
            title="Бесплатно" 
            desc="Подключить и использовать сервис можно бесплатно. Мы берём на себя весь трафик и экономим ваши затраты на SMS." 
          />
        </div>
      </div>

      <div className={css.faq}>
        <h2>Отвечаем на вопросы</h2>

        <div className={css.faqItems}>
          <FAQItem 
            question="Сколько стоит?" 
            answer="LeeCyber.ID предоставляется полностью бесплатно. Покроем все расходы на регистрационные SMS-сообщения для пользователей из России."
          />

        <FAQItem 
            question="Требуется ли заключение договора для компаний и индивидуальных предпринимателей?" 
            answer="Подключение сервиса возможно без оформления договорных отношений." 
          />

          <FAQItem 
            question="Доступен ли сервис для частных лиц?" 
            answer="Безусловно. После регистрации в персональном кабинете вы сможете интегрировать систему авторизации LeeCyber.ID в ваше приложение." 
          />

          <FAQItem 
            question="Как получить информацию об интеграции LeeCyber.ID?" 
            answer="Вся необходимая информация доступна в технической документации." 
          />
        </div>
      </div>
      <EndBlock />
    </MainContent>
  )
}